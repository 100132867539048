'use client'

import { AnalyticsBrowser } from "@segment/analytics-next"
import { usePathname } from 'next/navigation';
import React, { useEffect } from "react"

export const dynamic = "force-dynamic";


const AnalyticsTracker = () => {
  const pathName = usePathname();

  useEffect(() => {
    const analytics = AnalyticsBrowser.load({ writeKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY ?? 'JOeIyyslgctY6QHYfnuEYI7Y4ThGw2vE'});

    analytics.page({
      Action: 'Page Viewed', // TODO insert page name
      Category: 'Page Navigation',
      Interaction: 'Loaded'
    })
  }, [pathName])

  return (
    <></>
  )
}

export default AnalyticsTracker;