import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/(marketing)/_components/AnalyticsTracker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/(marketing)/_components/CookieBanner.tsx");
;
import(/* webpackMode: "eager" */ "/app/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/components/ui/sonner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"weight\":[\"100\",\"300\",\"400\",\"500\",\"700\",\"900\"],\"style\":[\"normal\",\"italic\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-roboto\"}],\"variableName\":\"roboto\"}");
